import { BFPanel, Loader } from '@integration-frontends/common/ui';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, Routes, unstable_HistoryRouter as Router } from 'react-router-dom';
import { SelectAttachment } from '../../../common/src';
import { Header } from '../../../common/src/ui/header';
import { environment } from '../environments/environment';
import './app.scss';
import UpdateProduct from './components/update-product/update-product';
import { EscapeIframeAndRedirect, useShopifyInit, useShopifyParams } from './shopify';
import { history, store } from './store';
import { ShopifySetApiKey } from './ui/shopify-set-api-key';
import { dynamicActivate } from 'i18n';

function AppContainer() {
  const { host } = useShopifyParams();

  const appBridgeConfig = {
    apiKey: environment.shopifyApiKey,
    host,
    forceRedirect: true,
  };

  useEffect(() => {
    dynamicActivate('en');
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <I18nProvider i18n={i18n}>
        <Provider store={store}>
          <AppProvider i18n={enTranslations}>
            <AppBridgeProvider config={appBridgeConfig}>
              <Router history={history}>
                <Routes>
                  <Route path="/escape-and-redirect" element={<EscapeIframeAndRedirect />} />
                  <Route path="*" element={<ShopifyApp />} />
                </Routes>
              </Router>
            </AppBridgeProvider>
          </AppProvider>
        </Provider>
      </I18nProvider>
    </div>
  );
}

function ShopifyApp() {
  const { shop, shopId } = useShopifyParams();
  useShopifyInit();

  const loader = (
    <div className="flex h-full w-full items-center justify-center">
      <Loader />
    </div>
  );

  return (
    <BFPanel className="shopify-panel flex h-full w-full flex-col overflow-y-hidden">
      <Suspense fallback={loader}>
        <Routes>
          <Route path="/welcome" element={<ShopifySetApiKey />} />
          <Route path="/select-attachment/*" element={<SelectAttachment />} />
          <Route
            path="/update-product"
            element={
              <>
                <Header />
                <div className="container py-6">
                  <UpdateProduct shop={shop} shopId={shopId} />`
                </div>
              </>
            }
          />
        </Routes>
      </Suspense>
    </BFPanel>
  );
}

export const App = AppContainer;
export default App;
