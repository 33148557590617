import { IconUploadAssets, IconUploadAssetsDisabled } from '@integration-frontends/common/ui';
import {
  attachmentSelected,
  multiSelectAttachmentEnded,
} from '@integration-frontends/integration/core/application';
import { AttachmentActionContainerBaseProps } from '@integration-frontends/integration/ui';
import { useMediaTypeSupport } from '@integration-frontends/integration/ui/common/use-media-type-support';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export function UploadActionContainer({
  selectedAttachments,
  isMultiSelect,
  render,
}: AttachmentActionContainerBaseProps) {
  const dispatch = useDispatch();
  const id = 'UPLOAD';
  const label = 'Upload';
  const [success, setSuccess] = useState(false);
  const [attachment] = selectedAttachments;
  const IconUpload = IconUploadAssets;
  const { isSupported } = useMediaTypeSupport();

  const uploadSingleAttachment = () => {
    dispatch(attachmentSelected({ attachment: attachment }));
    dispatch(multiSelectAttachmentEnded({ attachments: selectedAttachments }));
    setSuccess(true);
  };

  const endAttachmentSelection = () => {
    dispatch(multiSelectAttachmentEnded({ attachments: selectedAttachments }));
    setSuccess(true);
  };

  const onClick = () => {
    isMultiSelect ? endAttachmentSelection() : uploadSingleAttachment();
  };

  const enabled = isSupported(attachment?.mediaType);

  return render({
    id,
    Icon: enabled ? IconUpload : IconUploadAssetsDisabled,
    label,
    loading: false,
    success,
    enabled,
    onClick,
    ariaLabel: 'Upload',
  });
}
